import React from "react";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";
import styled from "styled-components";

import Section_ from "../QantevSection";

const Section = styled(Section_)`
  gap: 3em;

  @media (min-width: 500px) {
    gap: 10em;
  }
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  gap: 4em;
  padding: 0 10vw;

  p {
    color: #00033f;
    font-size: 18px;
    line-height: 1.7;
    font-weight: 300;
  }

  @media (min-width: 650px) {
    padding: 4em 8vw;
  }

  @media (min-width: 700px) {
    h1 {
      flex: 1;
    }
    > div {
      flex: 1;
    }
  }

  @media (min-width: 1024px) {
    flex-direction: row;
    justify-content: center;
    align-items: center;
  }
`;

const HowWeHelp = ({ children }) => {
  const { t } = useTranslation();

  return (
    <Section
      large
      style={{
        gridTemplateColumns: "1fr",
        gridTemplateRows: "100% 1fr",
      }}
    >
      <Section.Left>
        <Wrapper>
          <h2 className="big" style={{ textDecoration: "capitalize" }}>
            <Trans t={t} i18nKey="How We Do It">
              How
              <br />
              We Do It
            </Trans>
          </h2>
          <div>{children}</div>
        </Wrapper>
      </Section.Left>
    </Section>
  );
};

HowWeHelp.defaultProps = {
  children: null,
};
HowWeHelp.propTypes = {
  children: PropTypes.node,
};

export default HowWeHelp;
