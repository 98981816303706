import styled from "styled-components";

export const ImgWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;

  img {
    width: 280px;
    height: 280px;
  }
`;

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 10px 1fr;
  justify-content: center;
  align-items: start;
  gap: 2em;

  flex: 1;
`;

export const ItemTextWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr auto;
  gap: 16px;

  p {
    color: #707070;
  }

  > a {
    text-transform: uppercase;
    text-decoration: underline;
    color: #00033f;
  }

  h3 {
    font-size: 30px;
    line-height: 45px;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em calc(20vw / 2);
  background-image: linear-gradient(180deg, #e5e5e5, #fff);

  > *:not(:last-child) {
    margin-bottom: 5em;
  }

  @media (min-width: 850px) {
    flex-direction: row;

    > *:not(:last-child) {
      margin-bottom: 0;
      margin-right: 5em;
    }
  }
`;
