import styled from "styled-components";

import BGDotTexture from "assets/images/bg-dot-texture.svg";

export const ItemWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  flex-direction: column;
  justify-content: center;
  align-items: first baseline;
  gap: 2em;

  b {
    text-align: center;
    background-image: linear-gradient(180deg, #12ce9f 41%, #1f2058);
    font-size: 75px;
    line-height: 1;
    font-weight: 600;
    letter-spacing: normal;
    background-clip: text;
    background-image: -webkit-gradient(
      linear,
      left top,
      left bottom,
      color-stop(41%, #12ce9f),
      to(#1f2058)
    );
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  p {
    font-weight: 600;
  }
`;

export const ItemsWrapper = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr 1fr 1fr;
  justify-content: space-between;
  align-items: center;
  gap: 4em;
  text-align: center;

  @media (min-width: 650px) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr;
  }

  @media (min-width: 1024px) {
    grid-template-rows: none;
    grid-template-columns: 1fr 1fr 1fr 1fr;
  }
`;

export const SectionWrapper = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 5em calc(15vw / 2);
  gap: 2em;

  background-image: url(${BGDotTexture}),
    linear-gradient(135deg, #00033f, #1f2058);
  background-position: 0px 0px, 0px 0px;
  background-size: auto, auto;

  > h2 {
    color: #fff;
    font-size: 38px;
    line-height: 49px;
    font-weight: 600;
  }
  @media (min-width: 750px) {
    > h2 {
      font-size: 44px;
    }
  }
`;
