import React from "react";

import { graphql } from "gatsby";

import { useTranslation, Trans } from "gatsby-plugin-react-i18next";

import NetworkBlueGlob from "assets/icons/network-blue-glob.svg";
import ProfileDetail from "assets/icons/profile-detail.svg";
import DFWeeIcon from "assets/icons/wee-df.svg";
import Accenture from "assets/images/accenture.png";
import AWS from "assets/images/aws.png";
import ClaimsManagers from "assets/images/claims-managers.svg";
import DataFoundationWee from "assets/images/data-foundation-wee-df.svg";
import IBA from "assets/images/iba_white.png";
import PWC from "assets/images/logo_pwc_white.png";
import Microsoft from "assets/images/microsoft.png";
import NetworkManagersGlob from "assets/images/network-managers-glob.svg";
import ProcessWorkflow from "assets/images/process-workflow.svg";
import CallToAction from "components/CallToAction";
import Hero from "components/Hero";
import HowWeHelp from "components/HowWeHelp";
import InsurersEfficiency from "components/InsurersEfficiency";
import PageContainer from "components/PageContainer";
import Partners from "components/Partners";
import ProductList from "components/ProductList";
import QantevLink from "components/QantevLink";
import SEO from "components/SEO";

const IndexPage = () => {
  const { t } = useTranslation();

  return (
    <PageContainer>
      <Hero
        bgBlueGreen
        title={t("AI claims platform for health & life insurance")}
        img={
          <Hero.HeroImage
            src={ProcessWorkflow}
            alt="process workflow"
            loading="lazy"
            className="large"
          />
        }
        titleSmall
      >
        <p>
          {t(
            "Qantev helps clients focus on decision making, not data engineering"
          )}
        </p>
        <p>
          {t(
            "Our software enables health insurance operations to achieve excellence"
          )}
        </p>
        <QantevLink to="/demo" target="_blank" rel="noopener" $btn>
          {t("Request Demo")}
        </QantevLink>
      </Hero>
      <HowWeHelp>
        <p>
          {t("Qantev provides the ideal software suite for modern insurers.")}
        </p>
        <p>
          {t(
            "Our AI delivers business critical insight to health insurance operation teams, enabling efficient cost containment & optimal quality of service for the members."
          )}
        </p>
      </HowWeHelp>
      <ProductList>
        <ProductList.Item
          title={
            <>
              Data
              <br />
              Management
            </>
          }
          description={t(
            "Qantev data engineering technology allows automatic cleaning and refinement of complex health claims data."
          )}
          dividerIcon={<img src={DFWeeIcon} alt="data foundation wee" />}
          imgSrc={DataFoundationWee}
          imgAlt="data foundation"
          to="/product/data-foundation"
        />
        <ProductList.Item
          title={
            <>
              Network
              <br />
              Management
            </>
          }
          description={t(
            "Qantev’s AI assesses insurer health care networks and recommends effective strategies."
          )}
          dividerIcon={<img src={NetworkBlueGlob} alt="network blue" />}
          imgSrc={NetworkManagersGlob}
          imgAlt="network managers"
          to="/product/network-management"
        />
        <ProductList.Item
          title={
            <>
              Claims
              <br />
              Management
            </>
          }
          description={t(
            "Qantev streamlines pre-approval requests, highlights expensive quotes and recommends alternative health providers."
          )}
          dividerIcon={<img src={ProfileDetail} alt="claims" />}
          imgSrc={ClaimsManagers}
          imgAlt="claims managers"
          to="/product/claims-management"
        />
      </ProductList>
      <InsurersEfficiency
        title={
          <Trans
            t={t}
            i18nKey="Qantev improves insurers operational efficiency"
          >
            Qantev improves insurers
            <br /> operational efficiency
          </Trans>
        }
      >
        <InsurersEfficiency.Item
          title={t("Cost reduction across all claims")}
          number="-5%"
        />
        <InsurersEfficiency.Item
          title={t("Faster internal workflows and processes")}
          number="+30%"
        />
        <InsurersEfficiency.Item
          title={t("Readmission rate for health insurers")}
          number="-15%"
        />
        <InsurersEfficiency.Item
          title={t("Net Promoter Score")}
          number="+25%"
        />
      </InsurersEfficiency>
      <section
        style={{
          display: "flex",
          padding: "4em .5em",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          gap: "1em",
          textAlign: "center",
          color: "#00033f",
          background: "linear-gradient(180deg, #e5e5e5, #fff)",
          fontSize: "38px",
          lineHeight: "49px",
          fontWeight: "600",
        }}
      >
        <span>{t("Advanced analytics.")} </span>
        <span>{t("Actionable insights.")} </span>
        <span>{t("The future of health insurance operations.")} </span>
        <QantevLink to="/demo" target="_blank" rel="noopener" $btn>
          {t("Request Demo")}
        </QantevLink>
      </section>
      <Partners>
        <h2>{t("Our business partners")}</h2>
        <Partners.LogosRow>
          <img src={PWC} alt="pwc" className="small" />
          <img src={Accenture} alt="accenture" />
          <img src={IBA} alt="iba" />
          <img src={Microsoft} alt="microsoft" />
          <img src={AWS} alt="aws" className="small" />
        </Partners.LogosRow>
      </Partners>
      <CallToAction />
    </PageContainer>
  );
};

export default IndexPage;

// eslint-disable-next-line react/prop-types
export const Head = ({ location: { pathname } }) => (
  <SEO
    title="Home"
    description="Qantev delivers business critical insight to operational teams, enabling efficient cost containment & optimal quality of service for the members."
    pathname={pathname}
  />
);

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: { ns: { in: ["home", "common"] }, language: { eq: $language } }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
