import React from "react";

import { Link, useTranslation } from "gatsby-plugin-react-i18next";
import PropTypes from "prop-types";

import Divider from "components/Divider";

import {
  SectionWrapper,
  ItemWrapper,
  ImgWrapper,
  ItemTextWrapper,
} from "./styles";

const Item = ({ title, description, dividerIcon, imgSrc, imgAlt, to }) => {
  const { t } = useTranslation();

  return (
    <ItemWrapper>
      <ImgWrapper>
        <img src={imgSrc} alt={imgAlt} />
      </ImgWrapper>
      <Divider icon={dividerIcon} color="#00033f" />
      <ItemTextWrapper>
        <h3>{title}</h3>
        <p>{description}</p>
        <Link to={to}>{t("Learn more")} &gt;</Link>
      </ItemTextWrapper>
    </ItemWrapper>
  );
};

Item.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  description: PropTypes.string.isRequired,
  dividerIcon: PropTypes.element.isRequired,
  imgSrc: PropTypes.string.isRequired,
  imgAlt: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
};

SectionWrapper.Item = Item;

export default SectionWrapper;
