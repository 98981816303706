import React from "react";

import PropTypes from "prop-types";

import { ItemWrapper, SectionWrapper, ItemsWrapper } from "./styles";

const Item = ({ number, title }) => (
  <ItemWrapper>
    <b>{number}</b>
    <p>{title}</p>
  </ItemWrapper>
);

Item.propTypes = {
  number: PropTypes.string.isRequired,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
};

const InsurersEfficiency = ({ title, children }) => (
  <SectionWrapper>
    <h2>{title}</h2>
    <ItemsWrapper>{children}</ItemsWrapper>
  </SectionWrapper>
);

InsurersEfficiency.Item = Item;

InsurersEfficiency.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.element]).isRequired,
  children: PropTypes.node.isRequired,
};

export default InsurersEfficiency;
